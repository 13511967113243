import PolicyPage from 'Views/policy/PolicyPage'
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PolicyPageQuery } from 'Typings/generated-gatsby-types'

export default function Policy(props: PageProps<PolicyPageQuery>) {
  return <PolicyPage data={props.data?.strapiPolicy} path={props.path} />
}

export const query = graphql`
  query($language: String!) {
    strapiPolicy(locale: { eq: $language }) {
      text
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
