import React, { ElementType } from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import Text from 'Components/texts/Text'
import { localImageUrl } from 'Utils/api'
import styles from './Content.module.scss'

export type ContentProps = {
  className?: string
  text: string
  Component?: ElementType
}

export const Content = (props: ContentProps) => {
  const { className = '', text, ...other } = props

  if (!text) {
    return null
  }

  return (
    <Text className={classNames([styles.root, className])} variant="sm" {...other}>
      <ReactMarkdown
        className={styles.content}
        transformImageUri={localImageUrl}
        linkTarget="_blank"
      >
        {text}
      </ReactMarkdown>
    </Text>
  )
}
