import React from 'react'
import Menu from 'Components/layout/Menu'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'
import { Content } from 'Components/Content/Content'
import styles from './PolicyPage.module.scss'

const PolicyPage = (props: PageDataType) => {
  const { data } = props

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('PolicyPage', data)

  if (!data || !isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <div className={styles.root}>
        <Content text={data.text} />
      </div>
    </>
  )
}

export default PolicyPage
